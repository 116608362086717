import React from "react";

import { getIn } from "formik";

import { ReactLazyLoading } from "../../containers/ReactLoading";

const SugDashboard = ReactLazyLoading(() => import("../../customers/sug/dashboard"));

const SkDashboard = ReactLazyLoading(() => import("../../customers/sk/dashboard"));

type DashboardComponentsType = {
  default: {
    default?: React.ComponentType;
  };

  [customerIdentifier: string]: Partial<{
    default: React.ComponentType;
    [projectIdentifier: string]: React.ComponentType;
  }>;
};

const DASHBOARD_COMPONENTS: DashboardComponentsType = {
  "sevenit-gmbh": {
    sales: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/SalesDashboard")),
    "welcome-calls": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Welcome")),
    "upselling-6-2021": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Upselling621")),
    "upgrade-plan-b": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Outbound")),
    "implementation-calls": ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Outbound")),
    retention: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Retention")),
    default: ReactLazyLoading(() => import("../../customers/sevenit/dashboard/Support")),
  },

  "sug-ag": {
    default: SugDashboard,
  },

  "sug-gmbh": {
    default: SugDashboard,
  },

  "sk-dienstleistungen": {
    default: SkDashboard,
  },

  "lr-health-beauty": {
    service: ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/Service")),
    "outbound-exit": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/OutboundExit")),
    "lr-uk": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/UK")),
    "service-uk": ReactLazyLoading(() => import("../../customers/lr-health-beauty/dashboard/UKService")),
  },

  "lr-france": {
    service: ReactLazyLoading(() => import("../../customers/lr-france/dashboard/Service")),
  },

  termitel: {
    bewerbermanagement: ReactLazyLoading(
      () => import("../../customers/termitel/dashboard/bewerbermanagement/Dashboard"),
    ),
    vertrieb: ReactLazyLoading(() => import("../../customers/termitel/dashboard/Vertrieb")),
    default: ReactLazyLoading(() => import("../../customers/termitel/dashboard/verwaltung/Dashboard")),
  },

  hauck: {
    "pos-promotion": ReactLazyLoading(() => import("../../customers/hauk/dashboard/pos-promotion")),
    winterpromotion: ReactLazyLoading(() => import("../../customers/hauk/dashboard/winterpromotion")),
    default: ReactLazyLoading(() => import("../../customers/hauk/dashboard/default")),
  },

  proxytel: {
    default: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Dashboard")),
  },

  mphone: {
    default: ReactLazyLoading(() => import("../../customers/mphone/vertrieb/Dashboard")),
  },

  "abs-rz": {
    default: ReactLazyLoading(() => import("../../customers/abs/Dashboard")),
  },

  smr: {
    default: ReactLazyLoading(() => import("../../customers/smr/dashboard")),
  },
  carprotec: {
    support: ReactLazyLoading(() => import("../../customers/carpro-tec/Dashboard/Support")),
    "amg-line": ReactLazyLoading(() => import("../../customers/carpro-tec/Dashboard/AmgLine")),
  },
  "dm-edv": {
    default: ReactLazyLoading(() => import("../../customers/dm-edv/Dashboard")),
  },
  "mercedes-benz": {
    default: ReactLazyLoading(() => import("../../customers/mercedes-benz/Dashboard")),
  },

  zimmermann: {
    default: ReactLazyLoading(() => import("../../customers/zimmermann/Dashboard")),
  },

  demo: {
    default: ReactLazyLoading(() => import("../../customers/demo/dashboard")),
  },

  cityclean: {
    default: ReactLazyLoading(() => import("../../customers/cityclean/Dashboard")),
  },
  default: {
    default: () => {
      return null;
    },
  },
};

export const getDashboardComponent = ({ currentCustomer, currentProject }: SessionInterface) =>
  getIn(DASHBOARD_COMPONENTS, [currentCustomer.identifier, currentProject.identifier]) ||
  getIn(DASHBOARD_COMPONENTS, [currentCustomer.identifier, "default"]) ||
  getIn(DASHBOARD_COMPONENTS, ["default", "default"]);
