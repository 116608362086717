export const cityCleanNextContactValidation = (
  contact: BaseContactInterface,
  lastCallContact: Nilable<BaseContactInterface>,
): [boolean, string] => {
  if (contact.id === lastCallContact?.id) {
    return [true, ""];
  }

  const allowedStates = ["Verifikation ausstehend", "nicht erreichbar", "Kontaktstopp", "abgeschlossen"];

  if (contact.status && allowedStates.includes(contact.status.name)) {
    return [true, ""];
  }

  const toolTipText =
    "Bitte rufen Sie diesen Kontakt erst an oder setzen Sie ihn auf eine der folgenden Statusmöglichkeiten: „nicht erreichbar“, „Kontaktstopp“, „Verifikation ausstehend“";

  return [false, toolTipText];
};
