import { format } from "date-fns";

import { sessionPrefix } from "./helpers";

export const statsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats`;
export const dataPoolStatsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/data-pool`;
export const dataPoolByProjectStatsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/stats/data-pool-by-project`;

export const workerStatsPath = (session: LightSessionInterface, subPath: string | undefined = undefined) => {
  const path = `${sessionPrefix(session)}/stats/workers`;
  if (subPath) return path + "/" + subPath;
  return path;
};
export const showWorkerStatsPath = (
  session: LightSessionInterface,
  user: UserInterface,
  subPath: string | undefined = undefined,
) => {
  if (subPath) return `${sessionPrefix(session)}/stats/workers/${subPath}/${user.id}`;
  return `${sessionPrefix(session)}/stats/workers/${user.id}`;
};

export const ticketStatsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/tickets`;

export const hotseatStatsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/hotseat`;

export const appointmentStatsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/appointments`;

export const productiveHoursStatsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/stats/productive-hours`;

export const showProductiveHoursStatsPath = (session: LightSessionInterface, order: HourOrderType | Date) =>
  `${sessionPrefix(session)}/stats/productive-hours/details?date=${format(order instanceof Date ? order : order.month, "yyyy-MM-01")}`;

export const demoPerformancesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/performance`;
export const demoPerformancePath = (session: LightSessionInterface, user: UserInterface, fakeUser: UserInterface) =>
  `${sessionPrefix(session)}/stats/performance/${user.id}/${fakeUser.id}`;

export const sickDaysStatsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/stats/sick-days`;
