export const errorReload = (error: string, retries = 1) => {
  console.error(error);

  const urlString = window.location.href;

  const url = new URL(urlString);
  const errRetries = parseInt(url.searchParams.get("retries") || "0");
  if (errRetries >= retries) {
    window.history.replaceState(null, "", url.pathname);
    return;
  }

  url.searchParams.set("retries", String(errRetries + 1));
  window.location.href = url.toString();
};

// we just reload, because there isn't anything we can do anyway
window.addEventListener("vite:preloadError", (_event) => {
  errorReload("An error occured during a page load", 3);
});
