import { getIn } from "formik";
import type { TFunction } from "i18next";

import { cityCleanNextContactValidation } from "@/customers/cityclean/contacts/show/helpers";

import { ReactLazyLoading } from "../../containers/ReactLoading";
import { saveTask as saveAbsTask } from "../../customers/abs/Tasks/saveTask";
import { saveTask as saveDmEdvTask } from "../../customers/dm-edv/Tasks/saveTask";
import { saveTask as saveHauckHCTask } from "../../customers/hauk/CustomerContacts/horeca/saveTask";
import { saveTask as saveHauckNKTask } from "../../customers/hauk/CustomerContacts/neukundengewinnung/saveTask";
import { saveTask as saveMercedesTask } from "../../customers/mercedes-benz/Tasks/Form/saveTask";
import { saveTask as saveMphoneTask } from "../../customers/mphone/vertrieb/Tasks/Form/saveTask";
import { saveTask as saveProxytelTask } from "../../customers/proxytel/vertrieb/Tasks/Form/saveTask";
import { setContactField } from "../../customers/sevenit/contacts/show/projects/Upselling621HistoryCallback";
import { sugNextContactValidation } from "../../customers/sug/contacts/show/projects/helpers";
import { type QuestionnaireComponentType } from "./show/Questionnaire";

const SugContact = ReactLazyLoading(() => import("../../customers/sug/contacts/show/projects"));

type ComponentType =
  | "list"
  | "new"
  | "show"
  | "status-modal/addfields"
  | "agenda"
  | "project"
  | "histories/todos"
  | "histories/customerContacts"
  | "histories/mails"
  | "histories/callback"
  | "searchbar"
  | "export"
  | "saveTask"
  | "pipelineValidation";

type ComponentsType = {
  [key: string]: {
    [key: string]: {
      list?: React.ComponentType;
      new?: React.ComponentType;
      show?: React.ComponentType;
      "status-modal/addfields"?: React.ComponentType;
      agenda?: React.ComponentType;
      project?: React.ComponentType;
      "histories/todos"?: React.ComponentType;
      "histories/customerContacts"?: React.ComponentType;
      "histories/mails"?: React.ComponentType;

      "histories/callback"?: (
        session: SessionInterface,
        contact: BaseContactInterface,
        history: HistoryInterface,
      ) => Promise<void>;

      searchbar?: React.ComponentType;
      export?: React.ComponentType;

      saveTask?: (
        session: SessionInterface,
        existingTask: Nilable<TaskInterface>,
        states: ContactStatusInterface[],
        values: any,
        t: TFunction,
      ) => Promise<TaskInterface | undefined>;

      pipelineValidation?: (
        contact: BaseContactInterface,
        lastcallContact: Nilable<BaseContactInterface>,
      ) => [boolean, string];
    };
  };
};

const COMPONENTS: ComponentsType = {
  "sug-ag": {
    default: {
      project: SugContact,
      searchbar: ReactLazyLoading(() => import("@/customers/sug/contacts/SearchBar")),
      agenda: ReactLazyLoading(() => import("@/customers/sug/contacts/Agenda")),
      pipelineValidation: sugNextContactValidation,
    },
  },
  "sug-gmbh": {
    default: {
      project: SugContact,
      searchbar: ReactLazyLoading(() => import("@/customers/sug/contacts/SearchBar")),
      agenda: ReactLazyLoading(() => import("@/customers/sug/contacts/Agenda")),
      pipelineValidation: sugNextContactValidation,
    },
  },

  "sevenit-gmbh": {
    default: {
      list: ReactLazyLoading(() => import("@/customers/sevenit/contacts/List")),
      project: ReactLazyLoading(() => import("@/customers/sevenit/contacts/show/projects/Default")),
    },
    sales: {
      project: ReactLazyLoading(() => import("@/customers/sevenit/contacts/show/projects/Sales")),
    },
    "upselling-6-2021": {
      project: ReactLazyLoading(() => import("@/customers/sevenit/contacts/show/projects/Upselling621")),
      "histories/callback": setContactField,
    },
  },

  "lr-health-beauty": {
    default: {
      project: ReactLazyLoading(() => import("@/customers/lr-health-beauty/contacts/show/projects/Service")),
      "histories/todos": ReactLazyLoading(() => import("@/customers/lr-health-beauty/contacts/show/projects/Todos")),
    },
    "outbound-exit": {
      project: ReactLazyLoading(() => import("@/customers/lr-health-beauty/contacts/show/projects/Service")),
    },
  },

  "lr-france": {
    frankreich: {
      project: ReactLazyLoading(() => import("@/customers/lr-health-beauty/contacts/show/projects/Service")),
      "histories/todos": ReactLazyLoading(() => import("@/customers/lr-health-beauty/contacts/show/projects/Todos")),
    },

    service: {
      project: ReactLazyLoading(() => import("@/customers/lr-france/contacts/show/projects/ServiceFrance")),
      list: ReactLazyLoading(() => import("@/customers/lr-france/contacts/List")),
    },
  },

  proxytel: {
    vertrieb: {
      saveTask: saveProxytelTask,
      agenda: ReactLazyLoading(() => import("@/customers/proxytel/contacts/Agenda")),
    },
  },

  mphone: {
    vertrieb: {
      saveTask: saveMphoneTask,
      agenda: ReactLazyLoading(() => import("@/customers/mphone/contacts/Agenda")),
    },
  },

  termitel: {
    bewerbermanagement: {
      project: ReactLazyLoading(() => import("@/customers/termitel/contacts/show/projects/Bewerbermanagement")),
    },
    vertrieb: {
      project: ReactLazyLoading(() => import("@/customers/termitel/contacts/show/projects/Vertrieb")),
    },
  },

  "abs-rz": {
    vertrieb: {
      saveTask: saveAbsTask,
      agenda: ReactLazyLoading(() => import("@/customers/abs/Contacts/Agenda")),
    },
  },

  hauck: {
    "pos-promotion": {
      agenda: ReactLazyLoading(() => import("@/customers/hauk/contacts/agenda/pos-promotion")),
    },
    horeca: {
      project: ReactLazyLoading(() => import("@/customers/hauk/contacts/show/projects/default")),
      saveTask: saveHauckHCTask,
      agenda: ReactLazyLoading(() => import("@/customers/hauk/contacts/agenda/default")),
    },
    neukundengewinnung: {
      project: ReactLazyLoading(() => import("@/customers/hauk/contacts/show/projects/default")),
      saveTask: saveHauckNKTask,
      agenda: ReactLazyLoading(() => import("@/customers/hauk/contacts/agenda/default")),
    },
  },

  "dm-edv": {
    default: {
      saveTask: saveDmEdvTask,
      agenda: ReactLazyLoading(() => import("@/customers/dm-edv/contacts/Agenda")),
    },
  },

  "mercedes-benz": {
    default: {
      saveTask: saveMercedesTask,
      project: ReactLazyLoading(() => import("@/customers/mercedes-benz/contacts/show/Questionare")),
      searchbar: ReactLazyLoading(() => import("@/customers/mercedes-benz/contacts/Searchbar")),
      agenda: ReactLazyLoading(() => import("@/customers/mercedes-benz/contacts/Agenda")),
    },
  },

  zimmermann: {
    default: {
      new: ReactLazyLoading(() => import("@/customers/zimmermann/contacts/New")),
    },
  },

  cityclean: {
    "e-rechnungen": {
      project: ReactLazyLoading(() => import("@/customers/cityclean/contacts/show/Questionare")),
      pipelineValidation: cityCleanNextContactValidation,
    },
  },

  default: {
    default: {
      "histories/todos": ReactLazyLoading(() => import("./show/Histories/Todos")),
      "histories/customerContacts": ReactLazyLoading(() => import("./show/Histories/Tasks")),
      "histories/mails": ReactLazyLoading(() => import("./show/Histories/Mails")),
      list: ReactLazyLoading(() => import("./List")),
      new: ReactLazyLoading(() => import("./New")),
      show: ReactLazyLoading(() => import("./show")),
      agenda: ReactLazyLoading(() => import("./Agenda")),
      pipelineValidation: () => [false, ""],
    },
  },
};

export function getComponent(
  session: SessionInterface,
  type: "histories/todos",
): React.ComponentType<{ contact: BaseContactInterface; status: TodoStatusType }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/customerContacts",
): React.ComponentType<{ contact: BaseContactInterface }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/mails",
): React.ComponentType<{ contact: BaseContactInterface }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "project",
): QuestionnaireComponentType | { type: QuestionnaireComponentType; component: QuestionnaireComponentType } | undefined;

export function getComponent(
  session: SessionInterface,
  type: "status-modal/addfields",
): React.ComponentType<{ contact: Nullable<BaseContactInterface>; states: ContactStatusInterface[] }> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "list" | "new" | "show" | "agenda" | "histories/todos" | "searchbar" | "export",
): React.ComponentType | undefined;

export function getComponent(
  session: SessionInterface,
  type: "histories/callback",
): (session: SessionInterface, contact: BaseContactInterface, history: HistoryInterface) => Promise<void> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "saveTask",
): (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: any,
  t: TFunction,
) => Promise<TaskInterface | undefined> | undefined;

export function getComponent(
  session: SessionInterface,
  type: "pipelineValidation",
): (contact: BaseContactInterface, lastcallContact: Nilable<BaseContactInterface>) => [boolean, string];

export function getComponent({ currentCustomer, currentProject }: SessionInterface, type: ComponentType) {
  return (
    getIn(COMPONENTS, [currentCustomer.identifier, currentProject.identifier, type]) ||
    getIn(COMPONENTS, [currentCustomer.identifier, "default", type]) ||
    getIn(COMPONENTS, ["default", "default", type])
  );
}
