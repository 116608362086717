import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    id
    contactType
    type
    questionnaireType
    attrs
    reactionNeeded
    description
    sent

    insertedAt
    updatedAt

    contact {
      id
      displayName
      attrs

      infos {
        id
        itype
        value
        confirmedAt
      }
    }

    creator {
      ...UserFragment
    }

    editor {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const TASKS_LIST_QUERY = gql`
  query tasks($customerId: ID!, $projectId: ID!, $filters: TaskListFilters) {
    tasks(customerId: $customerId, projectId: $projectId, filters: $filters) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASKS_COUNT_QUERY = gql`
  query countTasks($customerId: ID!, $projectId: ID!, $filters: TaskListFilters) {
    countTasks(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const TASK_GET_QUERY = gql`
  query task($customerId: ID!, $projectId: ID!, $id: ID!) {
    task(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_MUTATION = gql`
  mutation mutateTask($customerId: ID!, $projectId: ID!, $id: ID, $values: TaskInput!) {
    mutateTask(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_DELETE_MUTATION = gql`
  mutation deleteTask($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTask(customerId: $customerId, projectId: $projectId, id: $id) {
      id
    }
  }
`;

export const TASK_SEND_MUTATION = gql`
  mutation sendTask($customerId: ID!, $projectId: ID!, $id: ID!) {
    sendTask(customerId: $customerId, projectId: $projectId, id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const GET_QUESTIONNAIRE_QUERY = gql`
  query questionnaireTask($customerId: ID!, $projectId: ID!, $contactId: ID!, $questionnaireType: String!) {
    questionnaireTask(
      customerId: $customerId
      projectId: $projectId
      contactId: $contactId
      questionnaireType: $questionnaireType
    ) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_OFFERS_LIST_QUERY = gql`
  query taskOffers($customerId: ID!, $projectId: ID!, $filters: TaskOfferListFilters) {
    taskOffers(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      start
      ends
    }
  }
`;

export const COUNT_TASK_OFFERS_QUERY = gql`
  query countTaskOffers($customerId: ID!, $projectId: ID!, $filters: TaskOfferListFilters) {
    countTaskOffers(customerId: $customerId, projectId: $projectId, filters: $filters)
  }
`;

export const GET_TASK_OFFER_QUERY = gql`
  query taskOffer($customerId: ID!, $projectId: ID!, $id: ID!) {
    taskOffer(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      start
      ends
      products {
        id
        name
        price
      }
    }
  }
`;

export const TASK_PRODUCTS_QUERY = gql`
  query taskProducts($customerId: ID!, $projectId: ID!, $filters: TaskProductListFilters) {
    taskProducts(customerId: $customerId, projectId: $projectId, filters: $filters) {
      id
      name
      price
    }
  }
`;

export const GET_TASK_PRODUCT_QUERY = gql`
  query taskProduct($customerId: ID!, $projectId: ID!, $id: ID!) {
    taskProduct(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      price
      offer {
        id
        name
      }
    }
  }
`;

export const TASK_OFFER_MUTATION = gql`
  mutation mutateTaskOffer($customerId: ID!, $projectId: ID!, $id: ID, $values: TaskOfferInput!) {
    mutateTaskOffer(customerId: $customerId, projectId: $projectId, id: $id, values: $values) {
      id
      name
      start
      ends
    }
  }
`;

export const DELETE_TASK_OFFERS_MUTATION = gql`
  mutation deleteTaskOffer($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTaskOffer(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      start
      ends
    }
  }
`;

export const TASK_PRODUCT_MUTATION = gql`
  mutation mutateTaskProduct($customerId: ID!, $projectId: ID!, $offerId: ID!, $id: ID, $values: TaskProductInput!) {
    mutateTaskProduct(customerId: $customerId, projectId: $projectId, offerId: $offerId, id: $id, values: $values) {
      id
      name
      price
    }
  }
`;

export const DELETE_TASK_PRODUCT_MUTATION = gql`
  mutation deleteTaskProduct($customerId: ID!, $projectId: ID!, $id: ID!) {
    deleteTaskProduct(customerId: $customerId, projectId: $projectId, id: $id) {
      id
      name
      price
    }
  }
`;

export const EXPORT_TASKS_MUTATION = gql`
  mutation exportTasks(
    $customerId: ID!
    $projectId: ID!
    $filters: TaskListFilters
    $fields: [String!]!
    $fieldNames: [String!]!
  ) {
    exportTasks(
      customerId: $customerId
      projectId: $projectId
      filters: $filters
      fields: $fields
      fieldNames: $fieldNames
    ) {
      id
    }
  }
`;

export const REGEN_TASK_POINTS_MUTATION = gql`
  mutation recalculateTaskPoints($starts: NaiveDateTime!, $stops: NaiveDateTime!) {
    recalculateTaskPoints(starts: $starts, stops: $stops) {
      id
    }
  }
`;
